<template>
	<div>
		<section 
			class="hero"
			:class="{ 'hero-start': startPage }"
		
			:style="[bgImage?{'backgroundImage':'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(' +bgImageStyle +')'}:{'background-color': '#000000'}]"
		>
			<div class="content-block">
				<div class="section-content-wrapper keep-size">
					<h1>{{ heading }}</h1>
					<p v-html="paragraph"></p>
					<dropdown v-if="withDropDown" />
					
				</div>

				<subscriptionForm v-if="hasForm"
					bg-color="black"
					text-color="white"
				/>
			</div>
		
			<video
				v-if="showVideo"
				class="videobgr"
				:src="videoBg"
				autoplay muted loop playsinline
			></video>
		</section>
	
	
	
	</div>

		
</template>

<script>
let videoExtensions = /(\.mp4|\.mov|\.avi|\.wmv|\.flv|\.webm)$/i

export default {
	name: 'Hero',
	components: {
		dropdown: () => import('@/components/DropdownMenu.vue'),
		subscriptionForm: () => import('@/components/SubscriptionForm.vue'),
	},
	props: {
		heading: {
			type: String,
			required: true,
			default: '',
		},
		paragraph: {
			type: String,
			required: false,
			default: '',
		},
		withDropDown: {
			type: Boolean,
			required: true,
			default: false,
		},
		bgImage: {
			type: String,
			required: false,
		},
		backgroundColor: {
			type: String,
			required: false,
		},
		startPage: {
			type: Boolean,
			required: false,
		},
		videoBg: {
			type: String,
			required: false,
			default: '',
		},
		hasForm: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data: function () {
		return {
			//showVideo: true,
		}
	},
	computed: {
		bgImageStyle: function () {
			if (this.bgImage && this.bgImage != '') {
				if (videoExtensions.exec(this.bgImage)) {
					return ''
				} else {
					return this.bgImage
				}
			}
			return ''
		},
		showVideo: function () {
			if (
				this.videoBg &&
				this.videoBg != '' &&
				this.videoBg.length > 0 &&
				videoExtensions.exec(this.videoBg)
			) {
				return true
			}
			return false
		},
	},
	mounted() {
		//this.showVideo = this.videoBg && this.videoBg != '' && this.videoBg.length > 0
	},
}
</script>
<style scoped lang="scss">
.hero {
	min-height: 590px;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	align-items: flex-start;
	padding: 20px;

	.content-block {
		margin: auto;
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 1410px;

		@include max-width(small) {
			flex-flow: column nowrap;
			padding-top: 50px;
		}
	}

	.section-content-wrapper {
		margin-top: 0;
		margin-bottom: 30px;
		flex-direction: column;
		z-index: 1;
		padding-right: 30px;
		p {
			max-width: 640px;
			font-size: 18px;
		}
		@include max-width(small) {
			margin-bottom: 0;
		}
		&.keep-size {
			@include min-width(large) {
				min-width: 0;
			}
		}
	}

	@include max-width(small) {
		min-height: 450px;
	}

	h1 {
		margin-bottom: 20px;

		@include max-width(small) {
			margin-bottom: 10px;
		}
	}
	h1 + p {
		font-size: 25px;
		margin-bottom: 25px;
		padding-left: 5px;
	}
}

.videobgr {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	z-index: -1;
	object-fit: cover;
}

.hero-start {
	height: 800px;
	position: relative;
	.section-content-wrapper {
		margin-bottom: auto;
		@include max-width(small) {
			margin-bottom: 60px;
		}
	}
	@include max-width(small) {
		height: 630px;
	}
}

.bannerHeader {
	.hero {
		margin-top: 50px;
	}
}
</style>
